
import * as imgproxyRuntime$Abg3gky4PD from '/usr/src/app/utils/nuxt-img/imgproxy.provider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgproxy",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp"
  ]
}

imageOptions.providers = {
  ['imgproxy']: { provider: imgproxyRuntime$Abg3gky4PD, defaults: {"imgProxyUrl":"https://cdn.jagofon.com/otf/","imgProxySalt":"","imgProxyKey":""} }
}
        