import JagofonFactory from '../jagofon-factory'
import type {
  Address,
  AddressType,
  ApiAddress,
  ApiPastOrder,
  Guest,
  GuestForm,
  JagofonApiResponse,
  PastOrder,
  SearchLocation,
} from '~/types/api'

export interface UpdateProfileForm {
  name: string
  phone: string
  email: string
}

export interface UpdatePasswordForm {
  currentPassword: string
  newPassword: string
  newPasswordConfirm: string
}

function formatAddress(type: AddressType, address: ApiAddress): Address {
  return {
    id: address.id,
    name: address.name,
    phone: address.phone,
    address: address.address,
    provinceId: address.province_id,
    province: address.province,
    cityId: address.city_id,
    city: address.city,
    districtId: address.district_id,
    district: address.district,
    subdistrictId: address.subdistrict_id,
    subdistrict: address.subdistrict,
    postCode: address.post_code,
    isDefault: address.is_default,
    type,
    location:
      `${address.subdistrict}, ${address.district}, ${address.city}, ${address.province}`
        .replace(
          /\w\S*/g,
          (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
        )
        .replace('Di', 'DI')
        .replace('Dki', 'DKI'),
  }
}

interface AddressForm {
  type: AddressType
  name: string
  phone: string
  address: string
  provinceId: number
  cityId: number
  districtId: number
  subdistrictId: number
  postCode: string
}

export default class UserRepository extends JagofonFactory {
  createGuestUser(data: GuestForm) {
    return this.call<JagofonApiResponse<Guest>>('POST', '/guest', {
      name: data.name,
      phone: data.phone,
      email: data.email,
      address: data.address,
      post_code: data.postCode,
      province_id: data.provinceId,
      city_id: data.cityId,
      district_id: data.districtId,
      subdistrict_id: data.subdistrictId,
    }).then(({ data }) => ({
      ...data,
      address: formatAddress('delivery', {
        ...data.address,
        is_default: true,
      }),
    }))
  }

  updateProfile(data: UpdateProfileForm) {
    return this.call('PUT', '/user/profile', data)
  }

  updatePassword(data: UpdatePasswordForm) {
    return this.call('POST', '/user/change_password', {
      old_password: data.currentPassword,
      new_password: data.newPassword,
      new_password_confirmation: data.newPasswordConfirm,
    })
  }

  getOrders() {
    return this.call<JagofonApiResponse<ApiPastOrder[]>>('GET', '/order').then(
      (response) =>
        response.data
          .map((order) => {
            return {
              id: order.id,
              orderNumber: order.ordernumber,
              total: order.total,
              status: order.status,
              statusId: order.status_id,
              createdAt: new Date(order.created_at),
              deliveryAddress: order.address_delivery,
              orderDetails: order.order_details.map((detail) => {
                const createdAt = new Date(order.created_at)
                return {
                  id: detail.id,
                  warrantyTerm: detail.warranty_term,
                  warrantyAmount: detail.warranty_amount,
                  warrantyExpiry: new Date(
                    createdAt.setDate(
                      createdAt.getDate() + detail.warranty_duration,
                    ),
                  ),
                  price: detail.price,
                  product: useProduct().formatProduct(detail.product),
                }
              }),
            } as PastOrder
          })
          .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
    )
  }

  getAddresses() {
    return this.call<
      JagofonApiResponse<{
        residential: ApiAddress | ''
        billing: ApiAddress | ''
        delivery: ApiAddress[]
      }>
    >('GET', '/user/addresses').then((response) => ({
      residential:
        response.data.residential === ''
          ? null
          : formatAddress('residential', response.data.residential),
      billing:
        response.data.billing === ''
          ? null
          : formatAddress('billing', response.data.billing),
      delivery: response.data.delivery.map((address) =>
        formatAddress('delivery', address),
      ),
    }))
  }

  createAddress(data: AddressForm) {
    return this.call<JagofonApiResponse<ApiAddress>>(
      'POST',
      '/user/addresses',
      {
        name: data.name,
        phone: data.phone,
        address: data.address,
        province_id: data.provinceId,
        city_id: data.cityId,
        district_id: data.districtId,
        subdistrict_id: data.subdistrictId,
        post_code: data.postCode,
        address_type: data.type,
      },
    ).then((response) => formatAddress(data.type, response.data))
  }

  updateAddress(id: number, data: AddressForm) {
    return this.call('PUT', `/user/addresses/${id}`, {
      name: data.name,
      phone: data.phone,
      address: data.address,
      province_id: data.provinceId,
      city_id: data.cityId,
      district_id: data.districtId,
      subdistrict_id: data.subdistrictId,
      post_code: data.postCode,
    })
  }

  deleteAddress(id: number) {
    return this.call('DELETE', `/user/addresses/${id}`)
  }

  setDefaultAddress(id: number) {
    return this.call('PUT', `/user/addresses/${id}/set-default`)
  }

  searchLocations(query: string) {
    return this.call<
      JagofonApiResponse<
        {
          subdistrict: {
            name: string
            id: number
          }
          district: {
            name: string
            id: number
          }
          city: {
            name: string
            id: number
          }
          province: {
            name: string
            id: number
          }
        }[]
      >
    >('POST', '/location/search', {
      query,
    }).then((response) => {
      return response.data.map(
        (location) =>
          ({
            label:
              `${location.subdistrict.name}, ${location.district.name}, ${location.city.name}, ${location.province.name}`
                .replace(
                  /\w\S*/g,
                  (txt) =>
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
                )
                .replace('Di', 'DI')
                .replace('Dki', 'DKI'),
            ...location,
          }) as SearchLocation,
      )
    })
  }

  updateNewsletter(subscribe: boolean) {
    const endpoint = subscribe ? '/user/subscribe' : '/user/unsubscribe'
    return this.call('POST', endpoint)
  }

  gopayToken() {
    return this.call<{
      data: {
        GOPAY_SAVINGS: number
        GOPAY_WALLET: number
        PAY_LATER: number
      }
    }>('GET', '/gopay-account')
  }

  gopayTokenLink() {
    return this.call<
      | {
          message: 'GoPay account link is active'
          data: {
            account_id: string
          }
        }
      | {
          message: 'GoPay account link is pending'
          data: {
            redirect_url: string
          }
        }
    >('POST', '/gopay-account')
  }

  gopayTokenUnlink() {
    return this.call('DELETE', '/gopay-account')
  }
}
