export default defineNuxtPlugin({
  name: 'sentry',

  async setup(nuxtApp) {
    const router = useRouter()
    const {
      public: { sentry },
    } = useRuntimeConfig()

    if (!sentry.dsn) {
      console.error('[sentry]: DSN is empty - cannot initialise Sentry')
      return {}
    }

    // HACK: Importing the function from the utils file to tree-shake dependencies
    const { init, browserTracingIntegration } = await import('~/utils/sentry')

    const client = init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: [browserTracingIntegration({ router })],

      tracesSampleRate: 0.05,

      release: sentry.release,

      ignoreErrors: [
        // Ignore 3rd party script errors
        'clarity.ms/tag/',
        'googletagmanager.com/gtag/',
      ],

      beforeSend(event, _hint) {
        event.tags ||= {}
        event.tags.locale = useNuxtApp().$i18n.locale.value

        const user = useAuth().data.value
        if (user) {
          event.user = {
            id: `${user.type}:${user.id}`,
            email: user.email,
            username: user.name,
          }
        }

        return event
      },
    })

    return {
      provide: {
        sentry: client,
      },
    }
  },
})
