export default defineNuxtPlugin({
  name: 'analytics',

  async setup(nuxt) {
    const segmentConfig = nuxt.$config.public.segment

    // Check if writeKey is set
    if (!segmentConfig.settings.writeKey) {
      console.error('[segment]: Write Key is empty - cannot initialise Segment')
      return {}
    }

    // Import `AnalyticsBrowser`
    const { AnalyticsBrowser } = await import('@segment/analytics-next')

    // Initialize Segment
    const segment = AnalyticsBrowser.load({
      writeKey: segmentConfig.settings.writeKey,
      // ...other options here
    })
    segment.debug(process.env.NODE_ENV === 'development')

    // Initialize PostHog
    segment.ready(async () => {
      const { posthog } = await import('posthog-js')

      posthog.init(nuxt.$config.public.posthog.apiKey, {
        api_host: 'https://us.i.posthog.com',
        capture_pageview: false,
        autocapture: false,
        segment: segment.instance,
        loaded: () => {
          // NOTE: Just using the `useScriptEventPage` hook seems to vary in reliability
          // from build to build, so we're using the `page` method here as a fallback.
          // This is not ideal, and should be revisited in the future.
          if (process.env.NODE_ENV === 'production') {
            if (!useRoute().fullPath.includes('embed')) {
              segment.page()
            }
          }

          useScriptEventPage(({ path }) => {
            if (path.includes('embed')) return

            segment.page()
          })
        },
      })
    })

    return {
      provide: {
        segment,
      },
    }
  },
})
