import kebabCase from 'just-kebab-case'
import type { Warranty } from '~/types/api'
import type { ApiSearchProduct, SearchProduct, Tag } from '~/types/api/search'

const specsSortOrder = [
  'processor',
  'storage',
  'memory',
  'casing',
  'color',
  'strap',
  'connectivity',
  'device',
]

export default function useProduct() {
  function formatSpecs(specs: { [key: string]: { name: string } }) {
    return Object.fromEntries(
      Object.entries(specs)
        .sort(
          (a, b) => specsSortOrder.indexOf(a[0]) - specsSortOrder.indexOf(b[0]),
        )
        .map(([key, value]) => [key, value.name]),
    )
  }

  function formatName(product: ApiSearchProduct) {
    return [
      product.model.name,
      ...Object.values(product.specs).map((value) => value.name),
    ].join(' - ')
  }

  function formatProduct(product: ApiSearchProduct): SearchProduct {
    return {
      id: product.id,
      price: product.price,
      condition: product.condition,
      name: formatName(product),
      model: product.model.name,
      brand: product.brand.name,
      parentPrice: product.parent.price,
      image: product.images[0]
        ? product.images[0].url
        : 'https://cdn.jagofon.com/product/404.png',
      images: product.images.map((image) => image.url),
      specs: formatSpecs(product.specs),
      tags: product.tags,
      isOnSale: product.is_on_sale,
      isSold: product.status_id !== 1,
      breadcrumbs: product.breadcrumbs,
      reviews: product.reviews,
      reseller: product.reseller?.name,
      details: product.details,
    }
  }

  function getDiscount(product: SearchProduct) {
    return Math.round(
      ((product.parentPrice - product.price) / product.parentPrice) * 100,
    )
  }

  function getUrl(product: SearchProduct) {
    return kebabCase(
      [
        product.brand,
        product.model,
        ...Object.values(product.specs),
        product.id.toString(),
      ]
        .join(' ')
        .toLowerCase(),
    )
  }

  function getSpecsList(product: SearchProduct) {
    return Object.values(product.specs)
  }

  function getProduct(id: number) {
    return useAsyncData(
      `product-${id}`,
      () => useNuxtApp().$api.product.getProductById(id),
      {
        dedupe: 'defer',
        getCachedData: (key, nuxt) =>
          nuxt.payload.data[key] || nuxt.static.data[key],
      },
    )
  }

  function getWarranties() {
    return useAsyncData(
      'warranties',
      () => useNuxtApp().$api.product.getWarranties(),
      {
        dedupe: 'defer',
        getCachedData: (key, nuxt): Warranty[] =>
          nuxt.payload.data[key] || nuxt.static.data[key],
      },
    )
  }

  async function getWarranty(id: number) {
    const warranties = await getWarranties()
    return warranties.data.value!.find((warranty) => warranty.id === id)!
  }

  function getWarrantyPrice(price: number, percent: string) {
    const value = parseInt(percent.replace('%', ''))
    return Math.ceil((price * value) / 100)
  }

  function getSignalStatuses() {
    return useAsyncData(
      'signal-statuses',
      () => useNuxtApp().$api.product.getSignalStatuses(),
      {
        dedupe: 'defer',
        getCachedData: (key, nuxt): Tag[] =>
          nuxt.payload.data[key] || nuxt.static.data[key],
      },
    )
  }

  return {
    formatProduct,
    getDiscount,
    getUrl,
    getSpecsList,
    getWarranties,
    getProduct,
    getWarranty,
    getWarrantyPrice,
    getSignalStatuses,
  }
}
