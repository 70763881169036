import JagofonFactory from '../jagofon-factory'
import type { JagofonApiResponse } from '~/types/api'
import type {
  ApiBuyback,
  ApiResaleProduct,
  ApiTradeIn,
  Buyback,
  ResaleProduct,
  CreateBuybackRequest,
  CreateTradeInRequest,
  TradeIn,
} from '~/types/api/resale'
import type { ApiSearchProduct } from '~/types/api/search'

export default class ResaleRepository extends JagofonFactory {
  // Trade in
  getTradeInAcceptedProducts(query: string) {
    return this.call<JagofonApiResponse<ApiResaleProduct[]>>(
      'GET',
      'trade-in/accepted-products',
      undefined,
      {
        query: { query },
      },
    ).then((response) =>
      response.data.map<ResaleProduct>((product) => ({
        id: product.id,
        model: product.model.name,
        specs: Object.fromEntries(
          Object.entries(product.specs).map(([key, value]) => [
            key,
            value.name,
          ]),
        ),
      })),
    )
  }

  getTradeInAvailableProducts(query: string, oldProductId: number) {
    return this.call<JagofonApiResponse<ApiSearchProduct[]>>(
      'GET',
      'trade-in/available-products',
      undefined,
      {
        query: { query, old_product: oldProductId },
      },
    ).then((response) => response.data.map(useProduct().formatProduct))
  }

  createTradeIn(data: CreateTradeInRequest) {
    return this.call<JagofonApiResponse<ApiTradeIn>>('POST', 'trade-in', {
      name: data.name,
      phone: data.phone,
      email: data.email,
      new_product_id: data.newProductId,
      old_product_id: data.oldProductId,
      warranty_id: data.warrantyId,
      screen_condition_id: data.screenConditionId,
      physical_condition_id: data.physicalConditionId,
      phone_issues: data.phoneIssues,
      is_phone_has_a_box: data.hasBox,
    }).then(
      ({ data }) =>
        ({
          id: data.id,
          referenceNumber: data.reference_number,
          warranty: data.warranty,
          screenCondition: data.screen_condition,
          physicalCondition: data.physical_condition,
          hasBox: data.is_phone_has_a_box,
          phoneIssues: data.phone_issues,
          customerPayAmount: data.customer_pay_amount,
          oldProduct: {
            name: data.old_product.name,
            price: data.old_product.price,
          },
          newProduct: {
            name: data.new_product.name,
            tag: data.new_product.tag,
            price: data.new_product.price,
          },
        }) as TradeIn,
    )
  }

  confirmTradeIn(id: number) {
    return this.call('PUT', `trade-in/${id}/confirm`)
  }

  // Buyback
  getBuybackProducts(query: string) {
    return this.call<JagofonApiResponse<ApiResaleProduct[]>>(
      'GET',
      'buyback/products',
      undefined,
      {
        query: { query },
      },
    ).then((response) =>
      response.data.map<ResaleProduct>((product) => ({
        id: product.id,
        model: product.model.name,
        specs: Object.fromEntries(
          Object.entries(product.specs).map(([key, value]) => [
            key,
            value.name,
          ]),
        ),
      })),
    )
  }

  createBuyback(data: CreateBuybackRequest) {
    return this.call<JagofonApiResponse<ApiBuyback>>('POST', 'buyback', {
      name: data.name,
      email: data.email,
      phone: data.phone,
      product_id: data.productId,
      warranty_id: data.warrantyId,
      screen_condition_id: data.screenConditionId,
      physical_condition_id: data.physicalConditionId,
      has_box: data.hasBox,
      phone_issues: data.phoneIssues,
    }).then(
      ({ data }) =>
        ({
          id: data.id,
          referenceNumber: data.reference_number,
          product: data.product,
          price: data.price,
          warranty: data.warranty,
          screenCondition: data.screen_condition,
          physicalCondition: data.physical_condition,
          hasBox: data.has_box,
          phoneIssues: data.phone_issues,
        }) as Buyback,
    )
  }

  confirmBuyback(id: number) {
    return this.call('PUT', `buyback/${id}`)
  }
}
