import JagofonFactory from '../jagofon-factory'
import type {
  AuthResponse,
  JagofonApiResponse,
  SocialProvider,
  SocialUrls,
} from '~/types/api'

export default class AuthRepository extends JagofonFactory {
  socialRedirectUrls() {
    return this.call<JagofonApiResponse<SocialUrls>>(
      'GET',
      'social/social_url',
    ).then((response) => response.data)
  }

  socialSignIn(provider: SocialProvider, query: any) {
    return this.call<JagofonApiResponse<AuthResponse>>(
      'GET',
      `social/${provider}`,
      undefined,
      { query },
    ).then((response) => response.data)
  }
}
