import JagofonFactory from '../jagofon-factory'
import type { JagofonApiResponse } from '~/types/api'

type InstallmentMethod = 'kredivo' | 'akulaku' | 'uangme' | 'indodana'

export interface ApiInstallmentOption {
  total_recurrence: number
  installment_amount: number
  interest_rate: number
  remarks?: string
}

export interface InstallmentOption {
  name: string
  amount: number
  interestRate: number
  remarks?: string
}

export default class PaymentRepository extends JagofonFactory {
  simulateInstallment(method: InstallmentMethod, amount: number) {
    const { t } = useI18n()
    return this.call<JagofonApiResponse<ApiInstallmentOption[]>>(
      'GET',
      `/paylater/${method}`,
      undefined,
      {
        query: { amount },
      },
    ).then((response) =>
      response.data.map(
        (option) =>
          ({
            name: t('global.installment-tenure', option.total_recurrence),
            amount: option.installment_amount,
            interestRate: option.interest_rate,
            remarks: option?.remarks,
          }) as InstallmentOption,
      ),
    )
  }

  getFee(slug: string, amount: number) {
    return this.call<JagofonApiResponse<{ name: string; amount: number }>>(
      'GET',
      `/fee/${slug}`,
      undefined,
      {
        query: {
          amount,
        },
        retry: false,
      },
    ).then((response) => response.data)
  }
}
