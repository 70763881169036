import JagofonFactory from '../jagofon-factory'
import type {
  ApiPastOrder,
  JagofonApiResponse,
  PastOrder,
  ShippingMethod,
  Voucher,
} from '~/types/api'

interface Cart {
  id: number
  user_id: number
  user_type: string
  status_id: number
  status: string
  created_at: string
}

export default class OrderRepository extends JagofonFactory {
  getVoucher(
    code: string,
    data: { amount: number; productIds?: number[]; warrantyAmount?: number },
  ) {
    return this.call<JagofonApiResponse<Voucher>>(
      'GET',
      `voucher/${code}`,
      undefined,
      {
        query: {
          amount: data.amount,
          'productIds[]': data.productIds,
          warrantyAmount: data.warrantyAmount,
        },
      },
    ).then((response) => response.data)
  }

  getShippingMethods() {
    return this.call<JagofonApiResponse<ShippingMethod[]>>(
      'GET',
      'shipping',
    ).then((response) =>
      response.data.map((method) => {
        return {
          id: method.id,
          name: method.name,
          slug: method.slug,
          order: method.order,
          price: 0,
          description: method.description,
          cities: method.cities,
        }
      }),
    )
  }

  getOrder(orderNumber: string) {
    return this.call<JagofonApiResponse<ApiPastOrder>>(
      'GET',
      `order/${orderNumber}`,
    ).then(({ data: order }) => {
      return {
        id: order.id,
        orderNumber: order.ordernumber,
        total: order.total,
        status: order.status,
        statusId: order.status_id,
        createdAt: new Date(order.created_at),
        deliveryAddress: order.address_delivery,
        orderDetails: order.order_details.map((detail) => {
          const createdAt = new Date(order.created_at)
          return {
            id: detail.id,
            warrantyTerm: detail.warranty_term,
            warrantyAmount: detail.warranty_amount,
            warrantyExpiry: new Date(
              createdAt.setDate(createdAt.getDate() + detail.warranty_duration),
            ),
            price: detail.price,
            product: useProduct().formatProduct(detail.product),
          }
        }),
      } as PastOrder
    })
  }

  addToCart(data: { productId: number; warrantyId: number; quantity: number }) {
    return this.call<JagofonApiResponse<Cart>>('POST', '/cart', {
      product_child_id: data.productId,
      warranty_id: data.warrantyId,
      quantity: data.quantity,
    }).then((response) => response.data)
  }

  checkout(
    cartId: number,
    data: {
      shippingId: number
      addressId: number
      voucherCode?: string
      paymentMethod: string
    },
  ) {
    return this.call<
      JagofonApiResponse<{
        invoice_url: string
      }>
    >('PUT', `/order/checkout/${cartId}`, {
      shipping_id: data.shippingId,
      address_id: data.addressId,
      promo_code: data.voucherCode,
      checkout_method: data.paymentMethod,
    }).then((response) => response.data)
  }
}
