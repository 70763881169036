import JagofonFactory from '../jagofon-factory'
import type { Faq, JagofonApiResponse, Content, Review, Seo } from '~/types/api'

export default class ContentRepository extends JagofonFactory {
  getContent(slug: string) {
    const { locale } = useI18n()

    return useAsyncData(
      `policy:${slug}:${locale.value}`,
      () => this.call<JagofonApiResponse<Content>>('GET', `/content/${slug}`),
      {
        transform: (response) => response.data,
        getCachedData: (key, nuxt) =>
          nuxt.payload.data[key] || nuxt.static.data[key],
      },
    )
  }

  getFaqs() {
    const { locale } = useI18n()
    return useAsyncData(
      `faqs:${locale.value}`,
      () => this.call<JagofonApiResponse<Faq[]>>('GET', '/faq'),
      {
        transform: (response) => response.data,
        default: () => [] as Faq[],
        getCachedData: (key, nuxt): Faq[] =>
          nuxt.payload.data[key] || nuxt.static.data[key],
      },
    )
  }

  getReviews() {
    return this.call<JagofonApiResponse<Review[]>>('GET', '/home/review')
  }

  getSeo(slug: string) {
    return this.call<JagofonApiResponse<Seo>>('GET', `/meta/${slug}`).then(
      (response) => response.data,
    )
  }
}
