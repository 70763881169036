import JagofonFactory from '../jagofon-factory'

type SlideSize = 'wide' | 'desktop' | 'tablet' | 'mobile' | 'origin'
type SlideImageFormat = 'jpg' | 'jpeg' | 'png' | 'avif' | 'webp'

type SlideImageKey = `${SlideSize}.${SlideImageFormat}`

const SlideImageFormatMap: Record<SlideImageFormat, string> = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  avif: 'image/avif',
  webp: 'image/webp',
}

const SlideSizeMediaMap: Record<SlideSize, string> = {
  wide: 'screen and (min-width: 1280px)',
  desktop: 'screen and (min-width: 1024px)',
  tablet: 'screen and (min-width: 768px)',
  mobile: 'screen and (min-width: 640px)',
  origin: '',
}

// NOTE: The height is doubled for retina images
const SlideHeightMap: Record<SlideSize, number> = {
  wide: 800,
  desktop: 600,
  tablet: 500,
  mobile: 500,
  origin: 500,
}

const SlideImageOrder: SlideImageKey[] = [
  'wide.jpg',
  'desktop.jpg',
  'tablet.jpg',
  'mobile.jpg',
  'origin.jpg',
  'wide.jpeg',
  'desktop.jpeg',
  'tablet.jpeg',
  'mobile.jpeg',
  'origin.jpeg',
  'wide.png',
  'desktop.png',
  'tablet.png',
  'mobile.png',
  'origin.png',
]

interface SlideMetaData {
  id: number
  name: string
  url: string
  url_is_internal: boolean
}

interface SlideImage {
  normal: string
  retina: string
  type: string
  media: string
}

export interface Slide extends Omit<SlideMetaData, 'url_is_internal'> {
  images: {
    [key in SlideImageKey]: SlideImage
  }
  originImage?: SlideImage
  voucher?: string
}

interface SlideResponse extends SlideMetaData {
  images: {
    [key in SlideImageKey]: string
  }
}

interface SliderResponse {
  success: boolean
  message: string
  data: SlideResponse[]
}

function fetchImage(url: string, args: object) {
  return useImage()(url, { quality: 55, ...args })
}

function transformImage(url: string, key: string) {
  const [size, type] = key.split('.')

  const normalUrl = url
  const retinaUrl = url.replace('@1x', '@2x')

  return ['avif', 'webp', type].map((format) => {
    const media = SlideSizeMediaMap[size as SlideSize]
    const type = SlideImageFormatMap[format as SlideImageFormat]
    const height = SlideHeightMap[size as SlideSize]

    return [
      `${size}.${format}`,
      {
        normal: fetchImage(normalUrl, { format, height }),
        retina: fetchImage(retinaUrl, { format, height }),
        type,
        media,
      },
    ]
  })
}

export default class SliderRepository extends JagofonFactory {
  // TODO: Move to content module
  async getSlides() {
    const { locale } = useI18n()
    const {
      data: { value: slides },
    } = await useAsyncData(
      `slides:${locale.value}`,
      () => this.call<SliderResponse>('GET', '/slider'),
      {
        transform: (response) =>
          response.data.map((slide) => {
            const sortedImages = SlideImageOrder.map((key) => [
              key,
              slide.images[key],
            ]).filter(([, value]) => value)

            const images = Object.fromEntries(
              sortedImages.flatMap(([key, url]) => transformImage(url, key)),
            ) as Slide['images']

            // TODO: Preload origin image
            const originImage = images['origin.avif']

            const voucher = /\?voucher=(.*)/g.exec(slide.url)

            return {
              id: slide.id,
              name: slide.name,
              url: slide.url,
              images,
              originImage,
              voucher: voucher ? voucher[1] : undefined,
            } as Slide
          }),
        default: () => [] as Slide[],
        getCachedData: (key, nuxt): Slide[] =>
          nuxt.payload.data[key] || nuxt.static.data[key],
      },
    )

    return slides
  }
}
