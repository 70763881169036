import { Buffer } from 'buffer'
import { joinURL, withBase } from 'ufo'
import hmacSHA256 from 'crypto-js/hmac-sha256.js'
import Base64url from 'crypto-js/enc-base64url.js'
import hex from 'crypto-js/enc-hex.js'
import type { ProviderGetImage } from '@nuxt/image'
import { createOperationsGenerator } from '#image'

const encodeUrlChars = (url: string) => {
  return url.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const urlSafeEncode = (data: Buffer | string) => {
  if (Buffer.isBuffer(data)) {
    return encodeUrlChars(data.toString('base64'))
  }
  return encodeUrlChars(Buffer.from(data, 'utf-8').toString('base64'))
}

const hexDecode = (hex: string) => Buffer.from(hex, 'hex')

const sign = (salt: string, target: string, secret: string) => {
  const msg = hexDecode(salt + Buffer.from(target).toString('hex'))
  const hmac = hmacSHA256(hex.parse(msg.toString('hex')), hex.parse(secret))
  const digest = hmac.toString(Base64url)
  return digest
}

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    resize: 'rs',
    size: 's',
    fit: 'rt',
    width: 'w',
    height: 'h',
    dpr: 'dpr',
    enlarge: 'el',
    extend: 'ex',
    gravity: 'g',
    crop: 'c',
    padding: 'pd',
    trim: 't',
    rotate: 'rot',
    quality: 'q',
    maxBytes: 'mb',
    background: 'bg',
    backgroundAlpha: 'bga',
    blur: 'bl',
    sharpen: 'sh',
    watermark: 'wm',
    preset: 'pr',
    cacheBuster: 'cb',
    stripMetadata: 'sm',
    stripColorProfile: 'scp',
    autoRotate: 'ar',
    filename: 'fn',
    format: 'f',
  },
  formatter: (key: string, value: string): string => `${key}:${value}`,
})

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, imgProxyUrl, imgProxySalt, imgProxyKey },
) => {
  const encodedUrl = urlSafeEncode(src)
  const path = joinURL('/', operationsGenerator(modifiers), encodedUrl)
  const signature = sign(imgProxySalt, path, imgProxyKey)

  return {
    url: withBase(joinURL(signature, path), imgProxyUrl),
  }
}
