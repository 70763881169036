import JagofonFactory from '../jagofon-factory'
import type {
  Breadcrumb,
  JagofonApiResponse,
  NavigationItem,
} from '~/types/api'

export default class NavigationRepository extends JagofonFactory {
  getNavigation() {
    return this.call<JagofonApiResponse<NavigationItem[]>>(
      'GET',
      '/navigation',
    ).then((response) => response.data)
  }

  getBreadcrumbs(type: string, slug: string) {
    return this.call<JagofonApiResponse<Breadcrumb[]>>(
      'GET',
      `/breadcrumbs/${type}/${slug}`,
    ).then((response) => response.data)
  }
}
