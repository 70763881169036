export default function useSentry() {
  const { $sentry } = useNuxtApp()

  function captureException(error: Error) {
    return $sentry?.captureException(error)
  }

  return {
    captureException,
  }
}
