import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill'

type Locale = 'en' | 'id'

async function polyfill(locale: Locale) {
  const unsupportedLocale = shouldPolyfill(locale)

  // This locale is supported
  if (!unsupportedLocale) {
    return
  }

  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-numberformat/polyfill-force')

  if (unsupportedLocale === 'en') {
    await import('@formatjs/intl-numberformat/locale-data/en')
  } else if (unsupportedLocale === 'id') {
    await import('@formatjs/intl-numberformat/locale-data/id')
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  await polyfill(nuxtApp.$i18n.locale.value as Locale)
})
