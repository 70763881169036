export default defineAppConfig({
  ui: {
    primary: 'teal',
    gray: 'neutral',
    accordion: {
      wrapper: 'border rounded-lg bg-white overflow-hidden',
      container: 'border-b last:border-b-0',
      default: {
        openIcon: 'i-far-chevron-down',
        class: 'mb-none',
      },
    },
    breadcrumb: {
      ol: 'gap-3',
      li: 'gap-3',
      base: 'font-normal',
      divider: {
        base: 'size-3',
      },
      active: 'text-black',
      default: {
        divider: 'i-far-chevron-right',
      },
    },
    button: {
      font: 'font-normal',
      icon: {
        size: {
          '2xs': 'size-3',
          xs: 'size-3',
          sm: 'size-3',
          md: 'size-4',
          lg: 'size-4',
          xl: 'size-5',
        },
      },
      size: {
        lg: 'text-sm md:text-base',
      },
      color: {
        white: {
          link: 'text-white dark:text-black underline-offset-4 hover:underline focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
        },
        black: {
          link: 'hover:no-underline md:hover:underline',
        },
        gray: {
          soft: 'bg-gray-700 text-white',
        },
      },
      default: {
        loadingIcon: 'i-far-spinner-third',
      },
    },
    card: {
      ring: 'ring-inset',
      shadow: '',
      divide: 'divide-y-0',
      body: {
        padding: 'p-6',
      },
    },
    carousel: {
      default: {
        prevButton: {
          icon: 'i-far-chevron-left',
        },
        nextButton: {
          icon: 'i-far-chevron-right',
        },
      },
    },
    input: {
      size: {
        '2xs': 'text-sm',
        xs: 'text-sm',
        sm: 'text-base',
        md: 'text-base',
        lg: 'text-base',
        xl: 'text-lg',
      },
      default: {
        loadingIcon: 'i-far-spinner-third',
      },
    },
    notification: {
      default: {
        closeButton: {
          icon: 'i-far-xmark',
        },
      },
    },
    pagination: {
      wrapper: 'items-stretch',
      default: {
        firstButton: {
          icon: 'i-far-chevrons-left',
        },
        prevButton: {
          icon: 'i-far-chevron-left',
        },
        nextButton: {
          icon: 'i-far-chevron-right',
        },
        lastButton: {
          icon: 'i-far-chevrons-right',
        },
      },
    },
    select: {
      icon: {
        size: {
          '2xs': 'size-3',
          xs: 'size-3',
          sm: 'size-3',
          md: 'size-4',
          lg: 'size-4',
          xl: 'size-5',
        },
      },
      default: {
        loadingIcon: 'i-far-spinner-third',
        trailingIcon: 'i-far-chevron-down',
      },
    },
    selectMenu: {
      default: {
        selectedIcon: 'i-far-check',
      },
    },
    modal: {
      overlay: {
        background: 'bg-gray-800/75',
      },
    },
  },
})
