import JagofonFactory from '../jagofon-factory'

interface Social {
  url: string
  type: string
}

interface SocialResponse {
  success: boolean
  data: Social[]
}

export default class SocialRepository extends JagofonFactory {
  async getSocials() {
    const {
      data: { value: socials },
    } = await useAsyncData(
      'socials',
      () => this.call<SocialResponse>('GET', '/social'),
      {
        transform: (response) => response.data,
        default: () => [] as Social[],
      },
    )

    return socials
  }
}
