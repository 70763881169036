import type { $Fetch, FetchOptions } from 'ofetch'
import BaseFactory from './base-factory'

export default class JagofonFactory extends BaseFactory<$Fetch> {
  protected call<T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    data?: object,
    options?: FetchOptions<'json'>,
  ): Promise<T> {
    return this.$fetch<T>(url, {
      method,
      body: data,
      ...options,
    })
  }
}
